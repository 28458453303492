import { render, staticRenderFns } from "./ContentBlockNewView.vue?vue&type=template&id=a738e8ba&scoped=true&"
import script from "./ContentBlockNewView.vue?vue&type=script&lang=js&"
export * from "./ContentBlockNewView.vue?vue&type=script&lang=js&"
import style0 from "./ContentBlockNewView.vue?vue&type=style&index=0&id=a738e8ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a738e8ba",
  null
  
)

export default component.exports