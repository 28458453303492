<template>
  <ModuleView :disable-breadcrumb="true">
    <template v-slot:body>
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isEditButtonVisible"
                type="button"
                class="btn btn-success"
                @click="goToEdit()"
              >
                <i class="fa fa-edit"></i> {{ $tc('buttons.edit') }}
              </button>
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save"></i> {{ $tc('buttons.save') }}
              </button>
              <ButtonDelete v-if="isDeleteButtonVisible" @deleteRecord="deleteRecord" />
              <ButtonClose route-name="contentBlock_list"></ButtonClose>
            </div>
          </div>
        </div>
      </section>
      <section :class="['content form-horizontal', { 'content-disabled': disabled }]">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-body">
              <div class="row">
                <div class="col-lg-8">
                  <h3>{{ $tc('contentBlock.main_settings') }}</h3>
                </div>
                <div class="form-group col-lg-4 text-right">
                  <div class="row">
                    <div class="col-lg-8">
                      <Checkbox
                        v-if="vlm"
                        v-model="contentBlock.mainSettings.isHomepage"
                        id="contentBlock_is_homepage"
                        :label="$tc('contentBlock.isHomepage')"
                        :disabled="disabled"
                      />
                    </div>
                    <div class="col-lg-4">
                      <Checkbox
                        v-model="contentBlock.mainSettings.hidden"
                        id="contentBlock_hidden"
                        :label="$tc('contentBlock.hidden')"
                        :disabled="disabled"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <input type="hidden" v-model="contentBlock.id">
              <div class="row form-group">
                <div class="col-lg-6">
                  <div class="m-b-5">
                    <label class="input-area-label">
                      {{ $tc('contentBlock.type') }} *
                    </label>
                  </div>
                  <label
                    v-for="(contentBlockType, index) in contentBlockTypeValues"
                    :key="`item-${index}`"
                    class="btn btn-secondary m-r-5"
                    :class="{
                      'active': contentBlock.type === contentBlockType.id,
                      'disabled': mainSettingTypeIsDisabled,
                    }"
                  >
                    <input
                      type="radio"
                      v-model="contentBlock.type"
                      :value="contentBlockType.id"
                      :data-test="'type_' + contentBlock.id"
                      :disabled="disabled"
                    >
                    {{ contentBlockType.title }}
                  </label>
                </div>
              </div>
              <InputField
                v-if="vlm"
                type="number"
                v-model.number="contentBlock.mainSettings.position"
                @blur="$v.contentBlock.mainSettings.position.$touch()"
                :error="$v.contentBlock.mainSettings.position.$error"
                id="contentBlock_position"
                :label="$tc('contentBlock.position')"
                :disabled="disabled"
                :min="0"
                required
              />
              <InputField
                v-model="contentBlock.mainSettings.title"
                @blur="$v.contentBlock.mainSettings.title.$touch()"
                :error="$v.contentBlock.mainSettings.title.$error"
                id="contentBlock_title"
                :label="$tc('contentBlock.title')"
                :disabled="disabled"
                required
              />
              <InputField
                v-model="contentBlock.mainSettings.identifier"
                @blur="$v.contentBlock.mainSettings.identifier.$touch()"
                :error="$v.contentBlock.mainSettings.identifier.$error"
                id="contentBlock_identifier"
                :label="$tc('contentBlock.identifier')"
                required
                :disabled="contentBlockIdIsDisabled"
              />
              <SelectField
                v-model="siteName"
                :options="sites"
                option-value="name"
                id="contentBlock_site"
                :label="$tc('contentBlock.site')"
                :disabled="disabled"
                @change="updateSiteId"
                @blur="$v.siteName.$touch()"
                :error="$v.siteName.$error"
                required
              />
              <SelectField
                v-model="contentBlock.mainSettings.category"
                :options="contentBlockCategories"
                id="contentBlock_category"
                :label="$tc('contentBlock.category')"
                :disabled="disabled"
                optionTitle="name"
                :optionValue="null"
                @blur="$v.contentBlock.mainSettings.category.$touch()"
                :error="$v.contentBlock.mainSettings.category.$error"
                required
              />
              <template v-if="!vlm">
                <InputField
                  type="number"
                  v-model="contentBlock.numberOfItems"
                  id="contentBlock_numberOfItems"
                  :label="$tc('contentBlock.number_of_items')"
                  :disabled="disabled"
                />
                <div class="row">
                  <div class="col-lg-3">
                    <InputField
                      type="number"
                      v-model="contentBlock.mainSettings.position"
                      id="contentBlock_position"
                      :label="$tc('contentBlock.position')"
                      :disabled="disabled"
                      :min="0"
                    />
                  </div>
                </div>
              </template>
              <template v-if="vlm">
                <h4 class="m-t-15 m-b-15">{{ $tc('contentBlock.items') }}</h4>
                <div class="row">
                  <div class="col-lg-4">
                    <InputField
                      type="number"
                      v-model.number="contentBlock.mainSettings.items.total"
                      @blur="$v.contentBlock.mainSettings.items.total.$touch()"
                      :error="$v.contentBlock.mainSettings.items.total.$error"
                      :label="$tc('contentBlock.total')"
                      :disabled="disabled"
                      :min="0"
                      required
                    />
                  </div>
                  <div v-if="contentBlock.type === 'article'" class="col-lg-4">
                    <InputField
                      type="number"
                      v-model.number="contentBlock.mainSettings.items.minRegional"
                      @blur="$v.contentBlock.mainSettings.items.minRegional.$touch()"
                      :error="$v.contentBlock.mainSettings.items.minRegional.$error"
                      :label="$tc('contentBlock.regional_min')"
                      :disabled="disabled"
                      :min="0"
                      required
                    />
                  </div>
                  <div v-if="contentBlock.type === 'article'" class="col-lg-4">
                    <InputField
                      type="number"
                      v-model.number="contentBlock.mainSettings.items.maxSporting"
                      @blur="$v.contentBlock.mainSettings.items.maxSporting.$touch()"
                      :error="$v.contentBlock.mainSettings.items.maxSporting.$error"
                      :label="$tc('contentBlock.sport_max')"
                      :disabled="disabled"
                      :min="0"
                      required
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="col-lg-6" v-if="[contentBlockTypes.article, contentBlockTypes.videoArticle].includes(contentBlock.type)">
            <div class="card card-body">
              <div class="row m-b-15">
                <div class="col-lg-12">
                  <h3>{{ $tc('contentBlock.fallback_settings') }}</h3>
                </div>
              </div>
              <template v-if="!vlm">
                <div class="form-group">
                  <Checkbox
                    v-model="contentBlock.contentSettings.articleType.external"
                    id="contentBlock_externalFallback"
                    :label="$tc('contentBlock.external_fallback')"
                    :disabled="disabled"
                  />
                  <Checkbox
                    v-model="contentBlock.contentSettings.articleType.article"
                    id="contentBlock_internalFallback"
                    :label="$tc('contentBlock.internal_fallback')"
                    :disabled="disabled"
                  />
                </div>
                <SelectField
                  v-model="contentBlock.contentSettings.sitesIds"
                  :options="sites"
                  id="contentBlock_falbackSite"
                  :label="$tc('contentBlock.fallback_site')"
                  @change="loadMainRubricsBySite"
                  :disabled="disabled"
                />
              </template>
              <div v-if="vlm" class="form-group">
                <MultiSelect
                  :value="selectedSites"
                  @input="updateSelectedSites"
                  :options="sites"
                  label="title"
                  :label-desc="$tc('contentBlock.sites_and_subsites')"
                  track-by="id"
                  id="contentBlock_sites"
                  :disabled="disabled"
                  :show-no-results="true"
                />
              </div>
              <div class="form-group">
                <MultiSelect
                  :value="selectedRubrics"
                  @input="updateRubrics"
                  :options="rubricsWithSites"
                  label="title"
                  track-by="id"
                  id="contentBlock_fallbackRubrics"
                  :disabled="disabled"
                  :label-desc="$tc('contentBlock.fallback_rubrics')"
                />
              </div>
              <div class="form-group">
                <MultiSelect
                  :value="contentBlock.contentSettings.tagsIds ? contentBlock.contentSettings.tagsIds.map(id => ({ id: id, title: tagTitleById(id) })) : []"
                  @input="onFallbackTagsInput"
                  :options="tags"
                  label="title"
                  :label-desc="$tc('contentBlock.fallback_tags')"
                  track-by="id"
                  :preselect-first="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :options-limit="300"
                  :limit="100"
                  :max-height="600"
                  :show-no-results="false"
                  @search-change="findTag"
                  id="contentBlock_fallbackTags"
                  :disabled="disabled"
                />
              </div>
              <div class="form-group">
                <MultiSelect
                  :value="contentBlock.contentSettings.geneeaTagsIds ? contentBlock.contentSettings.geneeaTagsIds.map(id => ({ id: id, title: geneeaTagTitleById(id) })) : []"
                  @input="onFallbackGeneeaTagsInput"
                  :options="geneeaTags"
                  label="title"
                  :label-desc="$tc('contentBlock.geneea_tags')"
                  track-by="id"
                  :preselect-first="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :options-limit="300"
                  :limit="100"
                  :max-height="600"
                  :show-no-results="false"
                  @search-change="findGeneeaTag"
                  id="contentBlock_geneeaTags"
                  :disabled="disabled"
                />
              </div>
              <template v-if="vlm">
                <div class="form-group">
                  <MultiSelect
                    :value="selectedAuthorSources"
                    @input="updateAuthorSources"
                    :options="authorSources"
                    label="name"
                    :label-desc="$tc('contentBlock.source')"
                    track-by="id"
                    :limit="100"
                    id="contentBlock_source"
                    :disabled="disabled"
                  />
                </div>
                <div class="form-group">
                  <MultiSelect
                    :value="selectedValueForAuthors"
                    @input="onAuthorsInput"
                    :options="authorOptions"
                    label="name"
                    :label-desc="$tc('contentBlock.author')"
                    track-by="id"
                    :preselect-first="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :options-limit="300"
                    :limit="100"
                    :max-height="600"
                    :show-no-results="false"
                    @search-change="findAuthor"
                    id="contentBlock_authors"
                    :disabled="disabled"
                  />
                </div>
              </template>
              <div v-if="!vlm" class="form-group">
                <MultiSelect
                  :value="contentBlock.contentSettings.categoriesIds ? contentBlock.contentSettings.categoriesIds.map(id => ({ id: id, title: categoryTitleById(id) })) : []"
                  @input="(value) => contentBlock.contentSettings.categoriesIds = value ? value.map(v => v.id) : []"
                  :options="categories"
                  label="title"
                  :label-desc="$tc('contentBlock.fallback_categories')"
                  track-by="id"
                  id="contentBlock_fallbackCategories"
                  :disabled="disabled"
                />
              </div>
              <div v-if="!vlm" class="form-group">
                <p><strong>{{ $tc('contentBlock.fallback_article_types') }}</strong></p>
                <Checkbox
                  v-model="contentBlock.contentSettings.articleType.article"
                  id="contentBlock_articleType_article"
                  label="Article"
                  :disabled="disabled"
                />
                <Checkbox
                  v-model="contentBlock.contentSettings.articleType.external"
                  id="contentBlock_articleType_external"
                  label="External"
                  :disabled="disabled"
                />
                <Checkbox
                  v-model="contentBlock.contentSettings.articleType.video"
                  id="contentBlock_articleType_video"
                  label="Video"
                  :disabled="disabled"
                />
                <Checkbox
                  v-model="contentBlock.contentSettings.articleType.quiz"
                  id="contentBlock_articleType_quiz"
                  label="Quiz"
                  :disabled="disabled"
                />
                <Checkbox
                  v-model="contentBlock.contentSettings.articleType.photostory"
                  id="contentBlock_articleType_photostory"
                  label="Photostory"
                  :disabled="disabled"
                />
              </div>
              <div v-if="!vlm" class="form-group">
                <p><strong>{{ $tc('contentBlock.fallback_article_flags') }}</strong></p>
                <Checkbox
                  v-model="contentBlock.contentSettings.flag.video"
                  id="contentBlock_flag_video"
                  label="Video"
                  :disabled="disabled"
                />
                <Checkbox
                  v-model="contentBlock.contentSettings.flag.imageGallery"
                  id="contentBlock_flag_imageGallery"
                  label="Image gallery"
                  :disabled="disabled"
                />
              </div>
              <template v-if="vlm">
                <div class="form-group">
                  <div class="form-group">
                    <h4 class="m-t-15 m-b-10">{{ $tc('contentBlock.flags') }}</h4>
                    <Choice
                      :name="'flags'"
                      v-model="selectedFlag"
                      :options="contentBlockFlags"
                    />
                  </div>
                  <div class="form-group">
                    <h4 class="m-t-15 m-b-10">{{ $tc('contentBlock.content') }}</h4>
                    <Checkbox
                      v-model="contentBlock.contentSettings.articleType.article"
                      id="contentBlock_setting_article"
                      :label="$tc('contentBlock.article')"
                      :disabled="disabled"
                    />
                    <Checkbox
                      v-model="contentBlock.contentSettings.articleType.external"
                      id="contentBlock_setting_external"
                      :label="$tc('contentBlock.external')"
                      :disabled="disabled"
                    />
                  </div>
                  <div class="form-group">
                    <h4 class="m-t-15 m-b-10">{{ $tc('contentBlock.fallback_article_settings') }}</h4>
                    <Checkbox
                      v-model="contentBlock.contentSettings.settings.prArticle"
                      id="contentBlock_setting_prArticle"
                      :label="$tc('contentBlock.pr')"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </template>
              <div v-if="!vlm" class="form-group">
                <p><strong>{{ $tc('contentBlock.fallback_article_settings') }}</strong></p>
                <Checkbox
                  v-model="contentBlock.contentSettings.settings.prArticle"
                  id="contentBlock_setting_prArticle"
                  :label="$tc('contentBlock.pr')"
                  :disabled="disabled"
                />
              </div>
              <div class="form-group">
                <InputField
                  type="number"
                  v-model="contentBlock.contentSettings.queryDaysLimit"
                  id="contentBlock_daysLimit"
                  :label="$tc('contentBlock.daysLimit')"
                  :disabled="disabled"
                  :min="0"
                  :max="365"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="contentBlock.type === contentBlockTypes.video">
            <div class="card card-body">
              <h3 class="form-edit-header">{{ $tc('contentBlock.fallback_settings') }}</h3>
              <div class="form-group">
                <Checkbox
                  v-model="contentBlock.contentSettings.articleType.article"
                  id="contentBlock_internalFallback"
                  :label="$tc('contentBlock.internal_fallback')"
                  :disabled="disabled"
                />
              </div>
              <SelectField
                v-model="contentBlock.contentSettings.sitesIds[0]"
                :options="sites"
                id="contentBlock_falbackSite"
                :label="$tc('contentBlock.fallback_site')"
                :disabled="disabled"
              />
            </div>
          </div>
        </div>
      </section>
    </template>
  </ModuleView>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentBlockModel, { CONTENT_BLOCK_FLAGS } from '@/model/ContentBlock'
import ContentBlockTypeMixin, { CONTENT_BLOCK_TYPE_ARTICLE } from '@/components/mixins/valueObject/ContentBlockTypeMixin'
import ModuleView from '@/components/ModuleView'
import MultiSelect from '@/components/form/select/MultiSelect'
import NotifyService from '@/services/NotifyService'
import PermissionService from '@/services/PermissionService'
import { MODULE_SYSTEM_CONTENT_BLOCK } from '@/model/ValueObject/UserPermissionModules'
import { maxLength, minLength, numeric, required } from 'vuelidate/lib/validators'
import ButtonClose from '@/components/shared/ButtonClose'
import ButtonDelete from '@/components/shared/ButtonDelete'
import Checkbox from '@/components/form/Checkbox'
import InputField from '@/components/form/inputs/Input'
import SelectField from '@/components/form/select/Select'
import Choice from '@/components/form/Choice.vue'

export default {
  name: 'ContentBlockNewView',
  mixins: [ContentBlockTypeMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ButtonClose,
    ButtonDelete,
    Checkbox,
    InputField,
    ModuleView,
    MultiSelect,
    SelectField,
    Choice
  },
  data () {
    return {
      contentBlockIdIsDisabled: false,
      authors: [],
      selectedAuthors: [],
      tags: [],
      selectedTags: [],
      geneeaTags: [],
      selectedGeneeaTags: [],
      dataLoaded: false,
      isLoading: false,
      contentBlock: this._.cloneDeep(ContentBlockModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_CONTENT_BLOCK_PERMISSIONS,
      contentBlockFlags: CONTENT_BLOCK_FLAGS,
      selectedFlag: null,
      flagMapping: {
        Video: 'video',
        'Photo gallery': 'imageGallery',
        Podcast: 'podcast',
        Quiz: 'quiz',
        Poll: 'poll',
        Discussion: 'discussion'
      }
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    authorOptions () {
      return this.authors.map(author => ({
        id: author.id,
        name: `${author.name} ${author.originName}`.trim()
      }))
    },
    selectedValueForAuthors () {
      return this.contentBlock.contentSettings.authorsIds
        ? this.contentBlock.contentSettings.authorsIds
          .map(id => {
            const author = this.authors.find(author => author.id === id)
            return author
              ? { id: author.id, name: `${author.name} ${author.originName}`.trim() }
              : null
          })
          .filter(author => author !== null)
        : []
    },
    selectedRubrics () {
      return (this.contentBlock.contentSettings.rubricsIds || []).map(id => ({
        id,
        title: this.rubricTitleByIds(id)
      }))
    },
    selectedAuthorSources () {
      // Map the IDs to the corresponding author sources with titles
      return this.contentBlock.contentSettings.authorSourcesIds
        .map(id => {
          const option = this.authorSources.find(source => source.id === id)
          return option ? { id: option.id, name: this.getOptionTitle(option) } : null
        })
        .filter(Boolean)
    },
    authorSources () {
      return this.$store.getters['source/list']
    },
    mainSettingTypeIsDisabled () {
      return !!this.contentBlock?.id
    },
    selectedSites () {
      return this.contentBlock.contentSettings.sitesIds.map(id => {
        return this.sites.find(site => site.id === id) || { id, title: 'Unknown site' }
      })
    },
    siteName: {
      get () {
        const site = this.$store.getters['site/siteById'](this.contentBlock.mainSettings.siteId)
        return site ? site.name : ''
      },
      set (newValue) {
        // Find the selected site by its name
        const selectedSite = this.sites.find(site => site.name === newValue)

        // Update siteId, handling the case where the selection is a placeholder ("--")
        if (selectedSite) {
          this.contentBlock.mainSettings.siteId = selectedSite.id
        } else {
          this.contentBlock.mainSettings.siteId = null // Reset if placeholder or invalid selection
        }
      }
    },
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.contentBlock?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return !this.disabled && this.hasPermission(this.requiredPermissions.saveButton)
    },
    isEditButtonVisible () {
      return this.disabled && this.hasPermission(this.requiredPermissions.editButton)
    },
    sites () {
      return this.$store.getters['site/enabledSitesForForm'](MODULE_SYSTEM_CONTENT_BLOCK, this.contentBlock, this.disabled)
    },
    rubrics () {
      return this.$store.getters['rubric/rubricsWithSite']
    },
    rubricsWithSites () {
      return this.$store.getters['rubric/rubricsWithSites']
    },
    categories () {
      return this.$store.getters['category/all']
    },
    contentBlockCategories () {
      return this.$store.getters['contentBlock/contentBlockCategory']
    }
  },
  validations: {
    siteName: {
      required: (value) => value !== null && value !== undefined && value !== ''
    },
    contentBlock: {
      mainSettings: {
        position: {
          required,
          numeric
        },
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(250)
        },
        identifier: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(250)
        },
        category: {
          required: (value) => !!(value && value.id)
        },
        items: {
          total: {
            numeric,
            required
          },
          minRegional: {
            numeric,
            required
          },
          maxSporting: {
            numeric,
            required
          }
        }
      }
    }
  },
  methods: {
    updateRubrics (value) {
      this.contentBlock.contentSettings.rubricsIds = value ? value.map(v => v.id) : []
    },
    rubricTitleByIds (id) {
      const rubric = this.rubricsWithSites.find(r => r.id === id)
      return rubric ? rubric.title : `Unknown (${id})`
    },
    initializeSelectedFlag () {
      // Find the flag that is set to true and select its corresponding title
      const selectedFlagKey = Object.keys(this.contentBlock.contentSettings.flag).find(
        key => this.contentBlock.contentSettings.flag[key]
      )
      if (selectedFlagKey) {
        // Map the flag key to the display name
        this.selectedFlag = Object.keys(this.flagMapping).find(
          title => this.flagMapping[title] === selectedFlagKey
        )
      }
    },
    updateAuthorSources (value) {
      // Update the IDs when the MultiSelect value changes
      this.contentBlock.contentSettings.authorSourcesIds = value ? value.map(v => v.id) : []
    },
    getOptionTitle (option) {
      return option.customText || option.name
    },
    siteTitleById (id) {
      const site = this.sites.find(site => site.id === id)
      return site ? site.title : ''
    },
    updateSelectedSites (selected) {
      this.contentBlock.contentSettings.sitesIds = selected.map(site => site.id)
      this.loadMainRubricsBySites(this.contentBlock.contentSettings.sitesIds)
    },
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission, this.contentBlock.site)
    },
    rubricTitleById (id) {
      return this.$store.getters['rubric/rubricById'](id)?.title ?? id
    },
    categoryTitleById (id) {
      return this.categories.find(category => category.id === id)?.title ?? id
    },
    tagTitleById (id) {
      return this.selectedTags.find(tag => tag.id === id)?.title ?? id
    },
    geneeaTagTitleById (id) {
      return this.selectedGeneeaTags.find(tag => tag.id === id)?.title ?? id
    },
    authorTitleById (id) {
      return this.selectedAuthors.find(author => author.id === id)?.name ?? id
    },
    updateSiteId () {
      const selectedSite = this.sites.find(site => site.name === this.contentBlock.site)
      if (selectedSite) {
        this.contentBlock.mainSettings.siteId = selectedSite.id
      }
    },
    onFallbackTagsInput (value) {
      this.contentBlock.contentSettings.tagsIds = value ? value.map(v => v.id) : []
      this.selectedTags = [...value]
    },
    onFallbackGeneeaTagsInput (value) {
      this.contentBlock.contentSettings.geneeaTagsIds = value ? value.map(v => v.id) : []
      this.selectedGeneeaTags = [...value]
    },
    onAuthorsInput (value) {
      this.contentBlock.contentSettings.authorsIds = value ? value.map(v => v.id) : []
      this.selectedAuthors = [...value]
    },
    async loadMainRubricsBySite (siteId) {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', siteId)
    },
    async loadMainRubricsBySites (siteIds) {
      await this.$store.dispatch('rubric/fetchRubricsBySiteIds', siteIds)
    },
    async getContentBlock () {
      try {
        await this.$store.dispatch('contentBlock/fetchOne', this.$route.params.id)
        this.contentBlock = this.$store.getters['contentBlock/detail']
        this.initializeSelectedFlag()

        const hasPermission = this.$store.getters['user/hasPermission'](this.requiredPermissions.editRoute, this.contentBlock.site)
        if (!hasPermission) {
          this.$router.push(`/contentBlock/${this.contentBlock.id}`)
          return
        }

        await Promise.all([
          this.loadMainRubricsBySites(this.contentBlock.contentSettings.sitesIds),
          this.$store.dispatch('rubric/lazyLoadRubricsByIds', this.contentBlock.contentSettings.rubricsIds),
          this.$store.dispatch('tag/loadTagsByIds', this.contentBlock.contentSettings.tagsIds),
          this.$store.dispatch('tag/loadGeneeaTagsByIds', this.contentBlock.contentSettings.geneeaTagsIds),
          this.$store.dispatch('author/loadAuthorsByIds', this.contentBlock.contentSettings.authorsIds),
          this.$store.dispatch('source/fetch', { limit: 100 })
        ])

        // Authors
        this.authors = this.$store.getters['author/searchedByTitle']
        this.selectedAuthors = [...this.authors]

        // Redactor tags
        this.tags = this.$store.getters['tag/list']
        this.selectedTags = [...this.tags]

        // Geneea tags
        this.geneeaTags = this.$store.getters['tag/listGeneea']
        this.selectedGeneeaTags = [...this.geneeaTags]
      } catch (error) {
        console.error(error)
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$tc('notify.please_fill_all_required_fields'))
        return
      }
      try {
        const newId = await this.$store.dispatch('contentBlock/create', this.contentBlock)
        const error = this.$store.getters['contentBlock/error']
        if (error) {
          NotifyService.setErrorMessage(error)
          return
        }
        NotifyService.setSuccessMessage(this.$tc('notify.record_was_created'))
        this.goToEdit(newId)
      } catch (error) {
        console.error(error)
      }
    },
    goToEdit (id) {
      return this.$router.push(`/contentBlock/${id ?? this.contentBlock.id}/edit`)
    },
    deleteRecord () {
      this.$store.dispatch('contentBlock/deleteRecord', this.contentBlock)
        .then(() => {
          const error = this.$store.getters['contentBlock/error']
          if (error) {
            NotifyService.setErrorMessage(error)
            return
          }

          NotifyService.setSuccessMessage(this.$tc('notify.record_was_deleted'))
          this.$router.push('/contentBlock')
        })
        .catch(console.error)
    },
    findTag (query) {
      this.isLoading = true
      this.$store.dispatch('tag/fetchByTitle', { query })
        .then(() => {
          this.tags = this.$store.getters['tag/list']
          this.isLoading = false
        })
    },
    findGeneeaTag (query) {
      this.isLoading = true
      this.$store.dispatch('tag/fetchByTitleGeneea', { query })
        .then(() => {
          this.geneeaTags = this.$store.getters['tag/listGeneea']
          this.isLoading = false
        })
    },
    findAuthor (query) {
      this.isLoading = true
      this.$store.dispatch('author/fetchSearchedByTitle', { query })
        .then(() => {
          this.authors = this.$store.getters['author/searchedByTitle']
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.$store.dispatch('contentBlock/fetchContentBlockCategory')
  },
  watch: {
    'contentBlock.type' (newValue, oldValue) {
      if (newValue !== CONTENT_BLOCK_TYPE_ARTICLE) {
        this.contentBlock.contentSettings = {}
        this.contentBlock.mainSettings.items.maxSporting = 0
        this.contentBlock.mainSettings.items.minRegional = 0
      }
      if (newValue === CONTENT_BLOCK_TYPE_ARTICLE) {
        this.contentBlock.contentSettings = ContentBlockModel.contentSettings
      }
    },
    selectedFlag (newValue, oldValue) {
      // Reset all flags to false
      Object.keys(this.contentBlock.contentSettings.flag).forEach(key => {
        this.contentBlock.contentSettings.flag[key] = false
      })

      // Map the display name to the flag key and set it to true
      const flagKey = this.flagMapping[newValue]
      if (flagKey) {
        this.contentBlock.contentSettings.flag[flagKey] = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .content-disabled {
    pointer-events: none;
  }
  .input-area-label {
    color: #8A96AC;
  }
  .btn-secondary {
    &.disabled {
      pointer-events: none;
    }
  }
</style>
